// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "d4_Jp";
export var authorHead__aboutAuthorTitle = "d4_Jn";
export var authorHead__aboutAuthorWrapper = "d4_Jm";
export var authorHead__description = "d4_Jk";
export var authorHead__flexWrapper = "d4_Jf";
export var authorHead__name = "d4_Jh";
export var authorHead__photo = "d4_Jg";
export var authorHead__position = "d4_Jj";
export var authorHead__socialWrapper = "d4_Jl";
export var authorHead__wrapper = "d4_Jd";